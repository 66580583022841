import { IS_DEV } from '@/lib/constants';
import { colorVars } from '@/theme/themeVars.css';
import { CircularProgress, Dialog, TextField } from '@mui/material';
import React from 'react';
import useLogin from '../Auth/useLogin';
import Button from '../ui/Button';
import Container from '../ui/Container';
import Flex from '../ui/Flex';
import Txt from '../ui/Txt';

const TEST_USER_EMAIL = IS_DEV && process.env.NEXT_PUBLIC_TEST_USER_EMAIL;
const TEST_USER_PW = IS_DEV && process.env.NEXT_PUBLIC_TEST_USER_PW;

const LoginForm = () => {
  const { login, loading, success, error, errors } = useLogin();

  const [email, setEmail] = React.useState(TEST_USER_EMAIL || '');
  const [password, setPassword] = React.useState(TEST_USER_PW || '');

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    login({ email, password });
  };

  React.useEffect(() => {
    if (success) {
      setEmail('');
      setPassword('');
    }
  }, [success]);

  return (
    <Container maxWidth="md">
      <Dialog
        open
        maxWidth="sm"
        slotProps={{
          backdrop: { sx: { bgcolor: 'transparent' } },
        }}
        PaperProps={{
          elevation: 10,
          sx: {
            bgcolor: 'background.paper',
            borderRadius: 6,
            overflow: 'hidden',
          },
        }}>
        <form onSubmit={handleSubmit} noValidate>
          <Flex direction="column" gap={4}>
            <Flex direction="column" paddingX={4} paddingTop={4}>
              <Txt variant="h6" bold color="primary">
                {`Log in to discover the ways in which we’re poised to empower you and your members.`}
              </Txt>
            </Flex>
            <Flex direction="column" gap={2} paddingX={4}>
              <TextField
                label="Email"
                variant="outlined"
                required
                type="email"
                autoComplete="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                label="Password"
                variant="outlined"
                required
                type="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Flex>
            <Flex>
              {error &&
                errors?.map((e) => (
                  <Txt key={e} color="error" variant="small" bold>
                    {e}
                  </Txt>
                ))}
            </Flex>
            <Flex
              direction="column"
              gap={2}
              sx={{
                bgcolor: colorVars.warmGrey100,
                padding: 4,
              }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="small"
                endIcon={loading && <CircularProgress color="inherit" size={16} />}
                disabled={loading}>
                {loading ? 'Logging in...' : 'Log in'}
              </Button>
            </Flex>
          </Flex>
        </form>
      </Dialog>
    </Container>
  );
};

export default LoginForm;
