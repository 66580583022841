import React from 'react';
import type { AuthenticateUserResponse, LoginPayload } from './authenticateUser';
import { authenticateUser, logout } from './authenticateUser';
import getCurrentUser from './getCurrentUser';
import { useAuth } from './useAuth';

export default function useLogin() {
  const [success, setSuccess] = React.useState<boolean | null>(null);

  const { user, loading, error, errors, setUser, setLoading, setError, setErrors, reset } =
    useAuth();

  const pingAuth = React.useCallback(async () => {
    if (!user) return;

    const result = await getCurrentUser();

    if (!result) {
      reset();
      return false;
    }
  }, [user]);

  return {
    logout: async () => {
      reset();
      logout();
      setSuccess(null);
    },
    login: async (payload: LoginPayload) => {
      setLoading(true);
      setSuccess(null);
      setErrors([]);
      setError(null);

      const res = await authenticateUser(payload).catch((e) => {
        setError(e);
        return {
          success: false,
          errors: [
            {
              message: e instanceof Error ? e.message : 'Unknown Error',
            },
          ],
          error: e,
        } as AuthenticateUserResponse;
      });

      if (!res.success || !res.user || res.error) {
        setLoading(false);
        setErrors(res.errors?.map((e) => e.message) || []);
        setSuccess(false);
        return false;
      }

      const authCheck = await pingAuth();

      setLoading(false);

      if (!authCheck) {
        setErrors(['Failed to authenticate']);
        setSuccess(false);
        return false;
      }

      setSuccess(true);
      setUser(res.user);

      return true;
    },
    pingAuth,
    success,
    user,
    loading,
    error,
    errors,
  };
}
