'use client';

import { Suspense } from 'react';
import Section from '../Section';
import LoginForm from './LoginForm';

const Login = () => {
  return (
    <Suspense fallback={null}>
      <Section id="login" title={''}>
        {/* <ScreenBox> */}
        <LoginForm />
        {/* </ScreenBox> */}
      </Section>
    </Suspense>
  );
};

export default Login;
