import ImageAsset, { MaybeImage } from '../common/ImageAsset';

export type SectionBackgroundProps = {
  image?: MaybeImage;
};

const SectionBackground = ({ image: maybeImage }: SectionBackgroundProps) => {
  const image = maybeImage && (
    <ImageAsset
      data={maybeImage}
      layout="fill"
      position="fixed"
      wrapProps={{
        sx: {
          willChange: 'transform',
          pointerEvents: 'none',
          height: '100vh',
          top: 0,
          left: 0,
          right: 0,
          zIndex: -1,
        },
      }}
    />
  );

  return <>{image}</>;
};

export default SectionBackground;
