'use client';

import { FEATURE } from '@/lib/constants';
import React from 'react';
import { AuthContext, AuthState, AuthStoreObject, createAuthStore, useAuth } from './useAuth';

type AuthProviderProps = React.PropsWithChildren<{
  value?: Partial<AuthState>;
  fallback?: React.ReactNode;
}>;

type AuthProviderInnerProps = React.PropsWithChildren<{
  fallback?: React.ReactNode;
}>;

const Inner = ({ children, fallback }: AuthProviderInnerProps) => {
  const loggedIn = useAuth((s) => !!s.user || FEATURE.LOGIN_DISABLED);

  return <>{loggedIn ? children : fallback}</>;
};

const AuthProvider = ({ value, fallback, children }: AuthProviderProps) => {
  const contextRef = React.useRef<AuthStoreObject>();

  if (!contextRef.current) {
    contextRef.current = createAuthStore(value);
  }

  return (
    <AuthContext.Provider value={contextRef.current}>
      <Inner fallback={fallback}>{children}</Inner>
    </AuthContext.Provider>
  );
};

export default AuthProvider;
