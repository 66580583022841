import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/@apollo/client-react-streaming/dist/index.cc.cjs");
;
import(/* webpackMode: "eager", webpackExports: ["SimulatePreloadedQuery"] */ "/vercel/path0/frontend/node_modules/@apollo/client-react-streaming/dist/index.cc.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/@liquorice/next-apollo/src/ApolloWrapper/ApolloWrapperInternal.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/frontend/node_modules/@mui/material/CssBaseline/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/@mui/material/styles/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Ftheme%2FthemeVars.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA8WWz3LaMBDG73kKzfgCU9BoV%2F%2FVUwHzHoSSJi2klLaQtpN379jG8q7tyZGMT%2F6s1U%2FfaleW3O2NPr4o8e9OiPm8eYMk1EfyjkmY4wtVdBKBKyaJw9PzZLvfHI4TJe1pd5iJ7Wa%2FnSiJYDRgJYkPQkkb7fkynQklfaVNZ2I0SvsuQqnzI4mYUrLlZM8mMW4UDZzLYxwOwDCgOkoFhsRg8xQgIaoGKZlZFuLzaNUC5dCoZ0ierDgORYakISBxAMUBMlAkXjOTZ8hAlCroGqi5y14ISgidV28brM5eX%2B9kw42sHje3z%2FV9EoWCoMOSqttKVQu1iFT9XKvGWaDqLomidOWqLKn6kESxXJauDFT9kkQR43K1YjM8JlE4v7CLT1R9quYNpSuRql%2BTKNaqLEs29lsSxWJVPVTdV2PXayodagus6Z8rjlHWszV9Z1uBtKLmIH2wLm8wRukdNmXIt4OFuVyIaLtKHO7IkZKNvHJatpIhtzmANDbU4MgLoR%2BGEruwWNVqTY8jBfGD4jUjsyL0EkzTCo6SNe2b61CX7boB7sQbj1vV1udVWxltk%2BRe4432epfhocOfDMmASPfV5G01jEh47cAWZgawX%2FwsQ24QPMkoyuhdU0a9tmZhih5H7bmNIzv5%2B%2FanyZm7Bb5uA8qRY9GEcMX6nt1eHKl4ibalj%2FwhL%2B%2BX7JfbJ%2FvPu9wH%2Ft7eKCjuNPSsVl67O1SI%2Bsrlp%2FEgrqtFlX%2FRbRDnQxKA%2FK8BmMR5c5rkCwSP0G9%2BNW9%2BtaleENNcpVXZZapPApQ6X5gYGvGRiTEJb%2FlFFjb1wJ54n4Srtdf%2FFQeqPTILAAA%3D%22%7D");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fcomponents%2FAppRoot%2FAppRoot.css.ts.vanilla.css%22%2C%22source%22%3A%22Ll8xYXNhZ2wzMCB7CiAgYmFja2dyb3VuZC1hdHRhY2htZW50OiBmaXhlZDsKICBiYWNrZ3JvdW5kLWNvbG9yOiAjMDAwOwogIGJhY2tncm91bmQtaW1hZ2U6IHJhZGlhbC1ncmFkaWVudChlbGxpcHNlIGZhcnRoZXN0LWNvcm5lciBhdCAyNSUgMjUlLCByZ2JhKDI4LDUwLDEwNywwKSA2MCUsIHJnYmEoOSw3LDM4LDAuMykgODAlLCByZ2JhKDksNywzOCwxKSAxMDAlKSwgcmFkaWFsLWdyYWRpZW50KGNpcmNsZSBhdCB0b3AsIHJnYmEoNzcsMTc1LDE4MCwxKSAwJSwgcmdiYSgyOCw1MCwxMDcsMSkgNjklLCByZ2JhKDE1LDE0LDQxLDEpIDEwMCUpOwp9Ci5fMWFzYWdsMzEgewogIG92ZXJzY3JvbGwtYmVoYXZpb3I6IG5vbmU7Cn0%3D%22%7D");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/src/app/global.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/src/components/AppRoot/AppRoot.css.ts");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/frontend/src/components/Auth/AuthProvider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/src/components/Login/Login.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/frontend/src/theme/mui/muiTheme.ts");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/src/theme/themeVars.css.ts");
