'use client';

import { createContext, useContext } from 'react';
import { createStore, useStore } from 'zustand';
import { User } from './parseUser';

export const AuthContext = createContext<AuthStoreObject | null>(null);

export interface AuthState {
  loading: boolean;
  error: Error | null;
  errors: string[] | null;
  user: User | null;
}

interface AuthActions {
  reset: () => void;
  setUser: (user: User) => void;
  clearUser: () => void;
  setError: (error: Error | null) => void;
  setErrors: (errors: string[] | null) => void;
  setLoading: (loading: boolean) => void;
}

interface AuthStore extends AuthState, AuthActions {}

export type AuthStoreObject = ReturnType<typeof createAuthStore>;

const initialAuthState: AuthState = {
  loading: false,
  error: null,
  errors: null,
  user: null,
};

export const createAuthStore = (initialState?: Partial<AuthState>) =>
  createStore<AuthStore>((set) => ({
    ...initialAuthState,
    ...initialState,
    setUser: (user) => set({ user }),
    clearUser: () => set({ user: null }),
    setError: (error) => set({ error }),
    setLoading: (loading) => set({ loading }),
    setErrors: (errors) => set({ errors }),
    reset: () => set(initialAuthState),
  }));

type AuthStoreSelector<T> = (state: AuthStore) => T;

export function useAuth(): AuthStore;
export function useAuth<T>(selector?: AuthStoreSelector<T>): T;
export function useAuth<T>(selector?: AuthStoreSelector<T>): T {
  const store = useContext(AuthContext);

  if (!store) {
    throw new Error('useAuth must be used within a AuthProvider');
  }

  return useStore(store, selector ?? (((state) => state) as AuthStoreSelector<T>));
}
