'use client';

import classNames from 'classnames';
import { motion } from 'framer-motion';
import React from 'react';
import { root, SectionVariants } from './Section.css';
import SectionBackground, { SectionBackgroundProps } from './SectionBackground';

export interface SectionProps {
  /**
   * The ID of the section.
   */
  id: string;
  /**
   * The title of the section.
   */
  title: string;
  /**
   * The slides in this section.
   */
  children?: React.ReactNode;

  background?: SectionBackgroundProps;
  className?: string;
}

const Section = React.forwardRef<HTMLDivElement, SectionProps & SectionVariants>(function SectionFn(
  { children, id, className, background, bg },
  ref,
) {
  return (
    <motion.section id={id} ref={ref} className={classNames(className, root({ bg }))}>
      {background && <SectionBackground {...background} />}
      {children}
    </motion.section>
  );
});

export default Section;
